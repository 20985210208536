

















































import { Component, Vue } from 'vue-property-decorator'
import { apiDailyData } from '@/api/finance/finance'

@Component
export default class FinanceDaily extends Vue {
    /** S Data **/
    DailyData = {}
    loading = false
    /** E Data **/

    /** S Methods **/
    // 获取日报统计
    financeDataCenter() {
        this.loading = true
        apiDailyData()
            .then(res => {
                this.loading = false
                this.DailyData = res
                console.log("this.DailyData", this.DailyData)
            })
            .catch(res => {
                this.loading = false
            })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.financeDataCenter()
    }
    /** E Life Cycle **/
}
